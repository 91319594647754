<script lang="ts" setup>

interface Props {
  iconName?: string;
  width?: number | string;
  height?: number | string;
}

const props = withDefaults(defineProps<Props>(), {
  iconName: 'default',
  width: '24',
  height: '24',
});

const viewBox = computed(() => `0 0 ${props.width} ${props.height}`);
</script>

<template>
  <ClientOnly>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      :aria-labelledby="iconName"
      role="presentation"
      fill="none"
    >
      <g>
        <slot />
      </g>
    </svg>
  </ClientOnly>
</template>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
